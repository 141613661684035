import React from "react";
import { useTranslation } from "react-i18next";
import { SheetActionIps } from "features/../../../shared/constants/enums";
import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import { supplierPreApprovalDeclined, ipsPreApprovalDeclined } from "./actions";
import DeclinePreApprovalForm from "../DeclinePreApprovalForm";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useDispatch } from "react-redux";

const DeclinePreApprovalIps = ({ sheet, sheetData, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();
  const { sheetId } = sheet;
  const availableActions = sheet
    ? sheet.availableActionsIps
    : sheetData.availableActionsIps;

  const isSupplier =
    availableActions[SheetActionIps.CanBePreApprovedBySupplier];

  const approver = isSupplier
    ? t("Supplier")
    : availableActions[SheetActionIps.CanBePreApprovedByIPS]
    ? t("IPSAdmin")
    : null;

  const submitAction = isSupplier
    ? supplierPreApprovalDeclined
    : ipsPreApprovalDeclined;

  if (approver == null) return null;

  return (
    <ModalBuilder
      submitAction={submitAction}
      submitParams={{ sheetId }}
      withForm={true}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.DeclinePreApproval.TriggerText", { approver })}
        </Table.MenuItem>
      )}
      onSubmitted={() => {
        dispatch(
          notifySuccess(
            t("SheetActions.DeclinePreApproval.NotificationText", { approver }),
          ),
        );
        onSubmitted();
      }}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal
          {...modalProps}
          header={t("SheetActions.DeclinePreApproval.ModalHeader")}
          className="add-agreement-modal"
        >
          <DeclinePreApprovalForm formProps={formProps} />
        </TranslatedModal>
      )}
    />
  );
};

export default DeclinePreApprovalIps;

import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import classNames from "classnames";
import { Image, Button } from "semantic-ui-react";
import {
  Lookup,
  Format,
  SidePanel,
  ListBuilder,
  Table,
} from "@redriver/cinnamon";
import NotesSidePanel from "../Notes";
import { CalculationBreakdownSidePanel } from "features/Sheets/ManageSheet/SheetActions";
import {
  getSheetState,
  getSheetDataState,
  LookupNames,
  ActionTypes,
  ImpersonateUsersLookupDropdown,
} from "features/Sheets";
import {
  setSidePanelVisibility,
  setSheetViewType,
  setImpersonateUser,
  defaultImpersonatedUser,
  getAudits,
} from "../actions";
import { Targets, Actions } from "constants/permissions";
import {
  PermissionCheck,
  UserTypeCheck,
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import {
  ContractType,
  SheetViewType,
  SheetViewTypeDescription,
  UserType,
} from "features/../../../shared/constants/enums";
import Scanner from "features/../../../shared/assets/scanner.svg";
import Building from "features/../../../shared/assets/building.svg";
import User from "features/../../../shared/assets/user.svg";
import RenameSheet from "./RenameSheet";
import { SheetViewConfirmationModal } from "../Shared";
import { invalidSystemScheduleItemsExist } from "features/Sheets/ManageSheet";
import { TablePagination } from "components/pagination";
import { getDealerOfficeHeaderInfo } from "features/../../../shared/features/DealerOffices";
import { useTranslation } from "react-i18next";

const SheetHeader = ({
  sheetId,
  sheetViewType,
  setSheetViewType,
  sheetDetailsSaving,
  calculating,
  invalidSchedule,
  customerToggle,
  customerView,
  setImpersonateUser,
  defaultImpersonatedUser,
  impersonatedUserFlicker,
  setImpersonatedUserFlicker,
  currentUser,
  hasPermission,
  location,
  preventUpdates,
}) => {
  const { t } = useTranslation("sheetHeader");
  const history = useHistory();
  const { isInternalUser } = currentUser;

  const [showOnSheetFirstLoad, setShowOnSheetFirstLoad] = useState(false);
  const disableShowOnSheetFirstLoad = () => setShowOnSheetFirstLoad(false);

  const [tz, setTz] = useState(undefined),
    SheetViewTypeButton = ({ sheetViewTypeToCheck, label, className }) => (
      <Button
        className={classNames(
          "view-button",
          sheetViewType === sheetViewTypeToCheck && "active",
          className,
        )}
        onClick={() => {
          setSheetViewType(sheetViewTypeToCheck);
          setImpersonatedUserFlicker(false);
          defaultImpersonatedUser();
        }}
      >
        {label}
      </Button>
    );

  const dispatch = useDispatch();
  useEffect(() => {
    (async function getHeaderInfo() {
      let headerInfo = await dispatch(getDealerOfficeHeaderInfo({ sheetId }));
      setTz(headerInfo.success ? headerInfo.response.timezone : null);
    })();
  }, [sheetId, dispatch]);

  useEffect(() => {
    setShowOnSheetFirstLoad(location?.state?.isNewSheet);
    history.replace({ state: {} });
  }, [location?.state?.isNewSheet, history]);

  if (!sheetId) return null;

  return (
    <Lookup
      lookup={LookupNames[ActionTypes.HeaderDetails]}
      lookupParams={{ sheetId }}
      resetOnMount={true}
      render={({ loading, response }, resetLookup) =>
        loading || !response ? null : (
          <React.Fragment>
            <div className="sheet-header">
              <div className="info-actions">
                <div className="sheet-title">
                  <h5>
                    {response.name}
                    <RenameSheet
                      sheetId={sheetId}
                      lookupResponse={response}
                      resetLookup={resetLookup}
                      showOnSheetFirstLoad={showOnSheetFirstLoad}
                      disableShowOnSheetFirstLoad={disableShowOnSheetFirstLoad}
                      disabled={preventUpdates}
                    />
                  </h5>
                  <small>{response.reference}</small>
                </div>
                <div className="sheet-icon-detail">
                  <Image src={Building} />
                  <p>{response.dealer}</p>
                </div>
                <div className="sheet-icon-detail">
                  <Image src={User} />
                  <p>{response.client}</p>
                </div>
                <div className="sheet-icon-detail">
                  <Image src={Scanner} />
                  <p>{ContractType[response.contractType]}</p>
                </div>
                <div className="history-and-action-buttons">
                  <div className="sheet-action-buttons">
                    <UserTypeCheck restrictTo={UserType.HQ}>
                      <SidePanel
                        dimmable
                        closeIcon
                        className="audit-list"
                        header={t("Audit_Log")}
                        trigger={
                          <Button primary icon="search" content={t("Audit")} />
                        }
                      >
                        <ListBuilder
                          withTable
                          loadAction={getAudits}
                          loadParams={{ sheetId }}
                          initialPagination={{
                            pageSize: 25,
                            pageNumber: 1,
                          }}
                          dataTransform={(result) => {
                            result.results.forEach((x) => {
                              switch (x.viewType) {
                                case SheetViewType.System:
                                case SheetViewType.IpsAdmin:
                                  x.className = "ips";
                                  break;
                                case SheetViewType.SupplierAdmin:
                                  x.className = "admin";
                                  break;
                              }
                            });
                            return result.results;
                          }}
                          totalTransform={(result) => result.totalResults}
                          renderList={(tableProps, state, events) => (
                            <React.Fragment>
                              <Table
                                {...tableProps}
                                dataKey="id"
                                emptyMessage={t("No_Audits_to_show")}
                              >
                                <Table.Column
                                  title={t("User")}
                                  render={(item) => (
                                    <React.Fragment>
                                      {(item.user || {}).value && (
                                        <span>{item.user.value}</span>
                                      )}
                                      <span>
                                        {
                                          SheetViewTypeDescription[
                                            item.viewType
                                          ]
                                        }
                                      </span>
                                    </React.Fragment>
                                  )}
                                />
                                <Table.Column
                                  title={t("Date_Time")}
                                  render={(item) => (
                                    <React.Fragment>
                                      <Format.Date
                                        timezone={tz}
                                        value={item.writtenUtc}
                                        format="DD MMM YYYY"
                                      />
                                      <Format.Date
                                        timezone={tz}
                                        value={item.writtenUtc}
                                        format="hh:mm a"
                                      />
                                    </React.Fragment>
                                  )}
                                />
                                <Table.Column
                                  title={t("Sheet_Status")}
                                  render={(item) =>
                                    process.env.TRADE_AS_IPS
                                      ? item.destinationStateIps?.value
                                      : item.destinationStatePrincipal?.value
                                  }
                                />
                              </Table>
                              <TablePagination
                                pageNumber={state.pagination.pageNumber}
                                onChange={events.onChangePagination}
                                pageSize={state.pagination.pageSize}
                                totalItems={state.total}
                                showingLabel="test"
                              />
                            </React.Fragment>
                          )}
                        />
                      </SidePanel>
                    </UserTypeCheck>
                    <NotesSidePanel title={t("Notes")} sheetId={sheetId} />
                    {isInternalUser &&
                      sheetViewType === SheetViewType.IpsAdmin && (
                        <CalculationBreakdownSidePanel />
                      )}
                  </div>
                </div>
              </div>
              <div className="view-buttons-impersonate">
                <div className="view-buttons">
                  {!customerView && (
                    <React.Fragment>
                      <label>{t("View")}</label>
                      <Button.Group>
                        <UserTypeCheck restrictTo={UserType.HQ}>
                          <SheetViewTypeButton
                            sheetViewTypeToCheck={SheetViewType.IpsAdmin}
                            label={t("IPS_Admin")}
                            className="ips-admin"
                          />
                        </UserTypeCheck>
                        <PermissionCheck
                          target={Targets.SheetAdmin}
                          action={Actions.Edit}
                        >
                          <SheetViewTypeButton
                            sheetViewTypeToCheck={SheetViewType.SupplierAdmin}
                            label={t("Supplier_Admin")}
                            className="supplier-admin"
                          />
                        </PermissionCheck>
                        <SheetViewTypeButton
                          sheetViewTypeToCheck={SheetViewType.AccountManager}
                          label={t("Account_Manager")}
                          className="account-manager"
                        />
                        <SheetViewConfirmationModal
                          disabled={
                            sheetDetailsSaving ||
                            calculating ||
                            invalidSchedule ||
                            customerView
                          }
                          customerToggle={customerToggle}
                          customerView={customerView}
                          triggerButtonClassName={classNames(
                            "view-button",
                            customerView && "active",
                            "customer",
                          )}
                          triggerButtonText={t("Customer")}
                          hideIcon={true}
                          setImpersonatedUserFlicker={
                            setImpersonatedUserFlicker
                          }
                        />
                      </Button.Group>
                    </React.Fragment>
                  )}
                </div>
                <UserTypeCheck restrictTo={UserType.HQ}>
                  <PermissionCheck
                    target={Targets.SheetAdmin}
                    action={Actions.ImpersonateUser}
                  >
                    {!customerView && (
                      <div className="impersonate">
                        <div className="tag">{t("Impersonate")}:</div>
                        <div className="header-dropdown">
                          {impersonatedUserFlicker ? (
                            <ImpersonateUsersLookupDropdown
                              clearable
                              search
                              placeholder="User..."
                              sheetId={sheetId}
                              onChange={(event, data) => {
                                if (data.value == "") {
                                  defaultImpersonatedUser();
                                  setSheetViewType(
                                    currentUser.userType === UserType.HQ
                                      ? SheetViewType.IpsAdmin
                                      : hasPermission(
                                          Targets.SheetAdmin,
                                          Actions.Edit,
                                        )
                                      ? SheetViewType.SupplierAdmin
                                      : SheetViewType.AccountManager,
                                  );
                                } else {
                                  const newImpUser = setImpersonateUser(data);
                                  setSheetViewType(
                                    newImpUser.isInternal
                                      ? SheetViewType.IpsAdmin
                                      : newImpUser.canEditAdminFields
                                      ? SheetViewType.SupplierAdmin
                                      : SheetViewType.AccountManager,
                                  );
                                }
                              }}
                            />
                          ) : (
                            setImpersonatedUserFlicker(true)
                          )}
                        </div>
                      </div>
                    )}
                  </PermissionCheck>
                </UserTypeCheck>
              </div>
            </div>
          </React.Fragment>
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  const { sheetViewType, sheetDetailsSaving, calculating, sheetData } =
    getSheetState(state);
  const { impersonatedUser, preventUpdates } = getSheetDataState(state);

  return {
    sheetViewType,
    sheetDetailsSaving,
    calculating,
    invalidSchedule: invalidSystemScheduleItemsExist(sheetData),
    impersonatedUser,
    preventUpdates,
  };
};

const actions = {
  setSidePanelVisibility,
  setSheetViewType,
  setImpersonateUser,
  defaultImpersonatedUser,
};

export default compose(
  connect(mapStateToProps, actions),
  withCurrentUser,
  withPermissions,
  withRouter,
)(SheetHeader);

export default {
    CanUpdate: "canUpdate",
    CanUnlock: "canUnlock",
    CanCrystallise: "canCrystallise",
    CanUncrystallise: "canUncrystallise",
    CanSendForApproval: "canSendForApproval",
    CanBeSignedByCustomer: "canBeSignedByCustomer",
    CanVoid: "canVoid",
    CanVoidEnvelope: "canVoidEnvelope",
    CanMakeLive: "canMakeLive",
    CanRequestRenew: "canRequestRenew",
    CanRenew: "canRenew",
    CanResurrect: "canResurrect",
    CanUpdateProductSchedule_Sales: "canUpdateProductSchedule_Sales",
    CanUpdateProductSchedule_Admin_SerialNumbers: "canUpdateProductSchedule_Admin_SerialNumbers",
    CanUpdateProductSchedule_Admin_NonSerialNumbers: "canUpdateProductSchedule_Admin_NonSerialNumbers",
    CanUpdateProductSchedule_Ips: "canUpdateProductSchedule_Ips",
    CanMarkProductScheduleByIps: "canMarkProductScheduleByIps",
    CanSetReadOnly: "canSetReadOnly",
    CanArchive: "canArchive",
    CanUnarchive: "canUnarchive",
    CanBePreApprovedBySupplier: "canBePreApprovedBySupplier",
    CanBePreApprovedByIPS: "canBePreApprovedByIPS",
    CanResetSheetState: "canResetSheetState"
};
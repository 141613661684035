import { apiPut } from "@redriver/cinnamon";

const ActionTypes = {
  preApprove: "SHEETS/PRE_APPROVE",
};

export const supplierPreApproveSheet = ({ sheetId }) =>
  apiPut(
    ActionTypes.preApprove,
    `sheets/${sheetId}/submit/supplier-pre-approval`,
  );

export const ipsPreApproveSheet = ({ sheetId }) =>
  apiPut(ActionTypes.preApprove, `sheets/${sheetId}/submit/ips-pre-approval`);

import React, { useState } from "react";
import { useDispatch, connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";

import classNames from "classnames";
import {
  ActionBar,
  Form,
  PageLoader,
  Content,
  ModalBuilder,
  notifySuccess,
} from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { withRouter } from "react-router-dom";
import { Button, Image, Icon, Transition } from "semantic-ui-react";
import Save from "features/../../../shared/assets/save.svg";
import RightArrow from "features/../../../shared/assets/white-right-arrow.svg";
import LeftArrow from "features/../../../shared/assets/white-left-arrow.svg";
import {
  SheetType,
  SheetViewType,
  UserType,
} from "features/../../../shared/constants/enums";
import { AppRoutes, AgreementRoutes } from "constants/routes";
import {
  updateData,
  calculateForm,
  saveDetails,
  loadSheetDetails,
  isDataPresent,
  downloadSheet,
  canCalculate,
  calculateFormOnChange,
  updateComparisonData,
  endSnapshot,
  downloadSheetAdminVersion,
} from "./actions";
import {
  canSheetBeUpdated,
  invalidSystemScheduleItemsExist,
  hasComparisonSnapshot,
} from "./helpers";
import {
  getSheetRatesState,
  getSheetState,
  getCalculationData,
} from "../selectors";
import {
  CloseConfirmationModal,
  DownloadConfirmationModal,
  ProductScheduleTriggerButton,
  SheetViewConfirmationModal,
} from "./Shared";
import {
  PermissionCheck,
  UserTypeCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

import { SaveAsModal, GenerateDocument } from "./SheetActions";
import { useTranslation } from "react-i18next";
import { setSelectedAgreementSchedule } from "features/Agreements";
import { SaveConfirmationModal } from "features/Sheets/ManageSheet/Shared";

const SheetStep = ({
  history,
  sheetId,
  title,
  headerActionBar,
  children,
  onNextStep,
  onPreviousStep,
  sheetData,
  sheetCanBeUpdated,
  invalidSchedule,
  updateData,
  saveDetails,
  loadSheetDetails,
  sheetDetailsLoading,
  sheetDetailsSaving,
  saveNotice,
  className,
  collapsed,
  toggleVisibility,
  calculating,
  missingYieldBands,
  calculationData,
  isDataPresent,
  displayNext = true,
  displayPrevious = true,
  displaySave = true,
  allowForRewrite,
  hasPermission,
  currentUser,
  customerView = false,
  displayCustomerToggle = false,
  customerToggle,
  impersonatedUser,
  setImpersonatedUserFlicker,
  errorMessage,
  updateComparisonData,
  endSnapshot,
  calculateFormOnChange,
  systemScheduleItems,
  largeFormatGroups,
  sheetViewType,
  scheduleId,
  setProductScheduleViewable,
  calculateForm,
  preventUpdates,
  inPreApprovalState,
}) => {
  const { t } = useTranslation("sheetSummary");
  const { t: tSheet } = useTranslation("sheetTranslation");
  const dispatch = useDispatch();

  let comparisonSnapshot = hasComparisonSnapshot(
    systemScheduleItems,
    largeFormatGroups,
  );

  const [displayUnitErrorVisible, setDisplayUnitErrorVisible] = useState(false),
    isAdmin =
      hasPermission(Targets.SheetAdmin, Actions.Edit) &&
      impersonatedUser.CanEditSheetAdmin;

  const canCalc = canCalculate(sheetData, calculationData);

  const redirectToAgreements = () => {
    dispatch(
      setSelectedAgreementSchedule({
        agreementId: null,
        scheduleId: scheduleId,
        sheetId: sheetId,
      }),
    );
    history.push(AgreementRoutes.Sheet(sheetId));
  };

  const { isInternalUser } = currentUser;

  const saveDisabled =
    !sheetCanBeUpdated ||
    (preventUpdates && !isInternalUser) ||
    sheetDetailsSaving ||
    calculating ||
    invalidSchedule;

  if (sheetDetailsLoading) {
    return <PageLoader />;
  }
  return (
    <div
      className={classNames("content-container", "sheet-step", className)}
      onKeyDown={(e) => {
        // prevent enter key propagation from adding array rows
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className="sheet-step-header" onClick={toggleVisibility}>
        <div className="title">
          {!!title && <h5>{title}</h5>}
          {toggleVisibility && (
            <Icon
              name="dropdown"
              rotated={collapsed ? "counterclockwise" : null}
            />
          )}
        </div>
        <div className="header-action-bar" onClick={(e) => e.stopPropagation()}>
          {headerActionBar && headerActionBar}
        </div>
      </div>
      <Form
        value={sheetData}
        onChange={(field, data, applyChanges) => {
          updateData(field, data, applyChanges);
          calculateFormOnChange(data);
        }}
        onKeyDown={(e) => {
          //enter key
          if (e.keyCode === 13) {
            calculateForm(true);
          }
        }}
        style={!title ? { marginTop: "-0.5rem" } : null}
        className="highlighted-inputs"
        disabled={sheetDetailsSaving || preventUpdates}
      >
        <div className={classNames("sheet-step-body")}>
          <Transition animation="fade down" visible={!collapsed} duration={500}>
            <div>{children}</div>
          </Transition>
          {errorMessage && <span className="calc-error">{errorMessage}</span>}
          {errorMessage && missingYieldBands && <span>, </span>}
          {missingYieldBands && (
            <span className="calc-error">{t("Yield_Band_Error")}</span>
          )}
        </div>
      </Form>
      <ActionBar>
        {customerView && (
          <ActionBar.Item align="left" fluid>
            <ProductScheduleTriggerButton
              setProductScheduleViewable={setProductScheduleViewable}
            />
          </ActionBar.Item>
        )}

        <ActionBar.Item align="left" fluid>
          {!customerView && displayPrevious && (
            <Button
              primary
              className="back-button"
              content={
                <div style={{ display: "flex" }} onClick={onPreviousStep}>
                  <Image src={LeftArrow} style={{ paddingRight: "0.5em" }} />
                  <p style={{ color: "white" }}>Back</p>
                </div>
              }
            />
          )}
        </ActionBar.Item>
        {saveNotice && (
          <ActionBar.Item align="right" fluid>
            {saveNotice(sheetData)}
          </ActionBar.Item>
        )}
        {sheetDetailsSaving && (
          <ActionBar.Item align="right" fluid>
            <PageLoader size="small" />
          </ActionBar.Item>
        )}
        {displayUnitErrorVisible && (
          <ActionBar.Item align="right">
            <Content className="warning-message">
              Please Select Unit in System Schedule
            </Content>
          </ActionBar.Item>
        )}
        {displayCustomerToggle && setImpersonatedUserFlicker && (
          <ActionBar.Item align="right">
            <SheetViewConfirmationModal
              disabled={sheetDetailsSaving || calculating}
              customerToggle={customerToggle}
              customerView={customerView}
              setImpersonatedUserFlicker={setImpersonatedUserFlicker}
            />
          </ActionBar.Item>
        )}

        {!process.env.TRADE_AS_IPS && (displaySave || displayNext) && (
          <ActionBar.Item align="right">
            <GenerateDocument
              sheet={sheetData}
              renderTrigger={(onRender) => (
                <SaveConfirmationModal
                  disabled={!canCalc || !sheetData.isDraft}
                  sheetId={sheetId}
                  sheetCanBeUpdated={sheetCanBeUpdated}
                  buttonContent={tSheet("generateDocumentModal.triggerText")}
                  buttonClassName="generate-btn"
                  onSubmitted={onRender}
                  skipHeaderUpdate={true}
                  cancelReload={true} // if a reload occurs, it prevents the modal showing due to re-rendering
                />
              )}
            />
          </ActionBar.Item>
        )}

        {(displaySave || displayNext) && ( //prevent duplicating on each tab on All View
          <PermissionCheck target={Targets.Sheet} action={Actions.Download}>
            {!customerView && sheetViewType != SheetViewType.AccountManager && (
              <UserTypeCheck restrictTo={UserType.HQ}>
                <PermissionCheck
                  target={Targets.Sheet}
                  action={Actions.CreateComparisonSnapshot}
                >
                  <ActionBar.Item align="right">
                    <ModalBuilder
                      renderTrigger={(showModal) => (
                        <Button
                          primary
                          className="save-button"
                          disabled={
                            sheetData.isDirty ||
                            preventUpdates ||
                            sheetDetailsSaving ||
                            calculating ||
                            invalidSchedule ||
                            !canCalc
                          }
                          onClick={showModal}
                        >
                          {" "}
                          {t("Create_Comparison_Snapshot")}
                        </Button>
                      )}
                      onSubmit={async () => {
                        if (isDataPresent() == true) {
                          /*We need to save and reload before creating a snapshot because:
                            1. This snapshot button is temporary
                            2. Saving and reloading ensures we have IDs set for all entities in the database before creating the snapshot
                            3. For maintainability reasons, perform this on the front-end instead of temporarily reworking the backend saving logic and then undoing.
                          */
                          await saveDetails(sheetId, false, null, true); // Sheet reload needs to be async rather than dispatched from inside of saveDetails
                          await loadSheetDetails(sheetId);

                          updateComparisonData();
                          saveDetails(sheetId);
                          endSnapshot();
                          setDisplayUnitErrorVisible(false);
                        } else {
                          setDisplayUnitErrorVisible(true);
                        }
                      }}
                      onSubmitted={(res) => {
                        if (res) {
                          dispatch(
                            notifySuccess(t("Comparison Snapshot Created")),
                          );
                        }
                      }}
                      disabled={
                        sheetDetailsSaving || calculating || invalidSchedule
                      }
                      renderModal={(modalProps) => (
                        <TranslatedModal.Confirmation {...modalProps}>
                          {comparisonSnapshot
                            ? t("confirm_re_create_snapshot")
                            : t("confirm_create_snapshot")}
                        </TranslatedModal.Confirmation>
                      )}
                    />
                  </ActionBar.Item>
                </PermissionCheck>
              </UserTypeCheck>
            )}
            {allowForRewrite && impersonatedUser.canDownloadSheet && (
              <ActionBar.Item align="right">
                <DownloadConfirmationModal
                  disabled={!canCalc}
                  sheetId={sheetId}
                  sheetDownloadFunction={downloadSheet}
                  isAdmin={isAdmin}
                  isRewrite={true}
                  sheetCanBeUpdated={sheetCanBeUpdated}
                  buttonContent="Print Rewrite Version"
                />
              </ActionBar.Item>
            )}

            {!customerView && impersonatedUser.canDownloadSheet && (
              <React.Fragment>
                <ActionBar.Item align="right">
                  <DownloadConfirmationModal
                    //ALWAYS disable for time being until the non-admin version is implemented
                    //once implemented we can remove the '|| true' condition
                    disabled={!canCalculate(sheetData, calculationData) || true}
                    sheetId={sheetId}
                    sheetDownloadFunction={downloadSheet}
                    isAdmin={false}
                    isRewrite={false}
                    sheetCanBeUpdated={sheetCanBeUpdated}
                    buttonContent="Print Version"
                  />
                </ActionBar.Item>
                <PermissionCheck
                  target={Targets.Sheet}
                  action={Actions.Download}
                >
                  <ActionBar.Item align="right">
                    <DownloadConfirmationModal
                      disabled={sheetDetailsSaving || !canCalc}
                      sheetId={sheetId}
                      sheetDownloadFunction={downloadSheetAdminVersion}
                      sheetCanBeUpdated={sheetCanBeUpdated}
                      buttonContent="Print Admin Version"
                    />
                  </ActionBar.Item>
                </PermissionCheck>
              </React.Fragment>
            )}
          </PermissionCheck>
        )}
        {displaySave ? (
          <React.Fragment>
            <ActionBar.Item align="right">
              <Button
                primary
                className="save-button"
                disabled={!inPreApprovalState && saveDisabled}
              >
                <div
                  onClick={() => {
                    isDataPresent() == true
                      ? [
                          saveDetails(sheetId),
                          setDisplayUnitErrorVisible(false),
                        ]
                      : [setDisplayUnitErrorVisible(true)];
                  }}
                >
                  Save
                  <Image src={Save} />
                </div>
              </Button>
            </ActionBar.Item>
            <ActionBar.Item align="right">
              <SaveAsModal
                scheduleId={sheetData.scheduleId}
                sheetId={sheetId}
                disabled={saveDisabled}
              />
            </ActionBar.Item>

            {!displayNext && (
              <ActionBar.Item align="right">
                <Button
                  primary
                  className="save-button"
                  disabled={saveDisabled}
                  onClick={() => {
                    isDataPresent() == true
                      ? [
                          saveDetails(sheetId, false, () =>
                            history.push(AppRoutes.Agreements),
                          ),
                          setDisplayUnitErrorVisible(false),
                        ]
                      : [setDisplayUnitErrorVisible(true)];
                  }}
                >
                  Save &amp; Finish
                  <Image src={Save} />
                </Button>
              </ActionBar.Item>
            )}
          </React.Fragment>
        ) : null}
        {!customerView && displayNext && (
          <ActionBar.Item align="right">
            <Button
              primary
              className="save-button"
              disabled={sheetDetailsSaving || calculating}
            >
              <div
                onClick={() => {
                  onNextStep();
                }}
              >
                Next
                <Image src={RightArrow} />
              </div>
            </Button>
          </ActionBar.Item>
        )}
        {sheetData.isDirty && displaySave && (
          <ActionBar.Item align="right">
            <CloseConfirmationModal
              disabled={sheetDetailsSaving || calculating}
              s
              buttonContent="Exit"
              onSubmit={() => history.push(AppRoutes.Agreements)}
              icon="close"
            />
          </ActionBar.Item>
        )}
        {!sheetData.isDirty && displaySave && (
          <ActionBar.Item align="right">
            <Button
              primary
              className="red-button"
              onClick={() => {
                redirectToAgreements();
              }}
              disabled={sheetDetailsSaving || calculating}
              icon="close"
              content="Exit"
            ></Button>
          </ActionBar.Item>
        )}
      </ActionBar>
    </div>
  );
};

SheetStep.propTypes = {
  sheetId: PropTypes.string,
  title: PropTypes.string,
  headerActionBar: PropTypes.element,
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  sheetData: PropTypes.object,
  saveNotice: PropTypes.func,
  displayNext: PropTypes.bool,
  displayPrevious: PropTypes.bool,
};

const actions = {
  updateData,
  saveDetails,
  loadSheetDetails,
  isDataPresent,
  updateComparisonData,
  endSnapshot,
  calculateFormOnChange,
  calculateForm,
};

const mapStateToProps = (state) => {
  const { sheetData, sheetDetailsLoading, sheetDetailsSaving, sheetViewType } =
      getSheetState(state),
    { sheetType } = getSheetRatesState(state);

  const allowForRewrite =
    sheetType == SheetType.Rewrite ||
    sheetType == SheetType.RewriteNewMasterDocument;

  return {
    sheetData,
    sheetDetailsLoading,
    sheetDetailsSaving,
    allowForRewrite,
    preventUpdates: sheetData.preventUpdates,
    scheduleId: sheetData.scheduleId,
    systemScheduleItems: sheetData.systemScheduleItems,
    largeFormatGroups: sheetData.largeFormatGroups,
    impersonatedUser: sheetData.impersonatedUser,
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
    invalidSchedule: invalidSystemScheduleItemsExist(sheetData),
    calculationData: getCalculationData(state),
    sheetViewType,
    inPreApprovalState: sheetData.inPreApprovalState,
  };
};

export default compose(
  connect(mapStateToProps, actions),
  withRouter,
  withPermissions,
  withCurrentUser,
)(SheetStep);

export default {
  Initialising: "Initialising",
  PreLive: "PreLive",
  PreLive_Draft: "PreLive_Draft",
  PreLive_AwaitingSupplierPreApproval: "PreLive_AwaitingSupplierPreApproval",
  PreLive_AwaitingIpsPreApproval: "PreLive_AwaitingIpsPreApproval",

  PreLive_QueuedForDocusign: "PreLive_QueuedForDocusign",

  PreLive_WithCustomer_Docusign: "PreLive_WithCustomer_Docusign",
  PreLive_WithCustomer_Docusign_Waiting:
    "PreLive_WithCustomer_Docusign_Waiting",
  PreLive_WithCustomer_Docusign_Checking:
    "PreLive_WithCustomer_Docusign_Checking",

  PreLive_WithSupplierAdmin_Docusign: "PreLive_WithSupplierAdmin_Docusign",
  PreLive_WithSupplierAdmin_Docusign_Waiting:
    "PreLive_WithSupplierAdmin_Docusign_Waiting",
  PreLive_WithSupplierAdmin_Docusign_Checking:
    "PreLive_WithSupplierAdmin_Docusign_Checking",

  PreLive_WithSupplierSignatory_Docusign:
    "PreLive_WithSupplierSignatory_Docusign",
  PreLive_WithSupplierSignatory_Docusign_Waiting:
    "PreLive_WithSupplierSignatory_Docusign_Waiting",
  PreLive_WithSupplierSignatory_Docusign_Checking:
    "PreLive_WithSupplierSignatory_Docusign_Checking",

  PreLive_WithIPSAdmin_Docusign: "PreLive_WithIPSAdmin_Docusign",
  PreLive_WithIPSAdmin_Docusign_Waiting:
    "PreLive_WithIPSAdmin_Docusign_Waiting",
  PreLive_WithIPSAdmin_Docusign_Checking:
    "PreLive_WithIPSAdmin_Docusign_Checking",

  PreLive_WithIPSSignatory_Docusign: "PreLive_WithIPSSignatory_Docusign",
  PreLive_WithIPSSignatory_Docusign_Waiting:
    "PreLive_WithIPSSignatory_Docusign_Waiting",
  PreLive_WithIPSSignatory_Docusign_Checking:
    "PreLive_WithIPSSignatory_Docusign_Checking",

  PreLive_AwaitingIpsSignature: "PreLive_AwaitingIpsSignature",
  PreLive_AwaitingIpsSignature_Waiting: "PreLive_AwaitingIpsSignature_Waiting",
  PreLive_AwaitingIpsSignature_CheckingDocusign:
    "PreLive_AwaitingIpsSignature_CheckingDocusign",

  PreLive_IPS_Approved: "PreLive_IPS_Approved",
  Live: "Live",
  Retired: "Retired",
  Dead: "Dead",

  CustomerApprovalDeclined: "CustomerApprovalDeclined",
  SupplierAdminDeclined: "SupplierAdminDeclined",
  SupplierSignatoryDeclined: "SupplierSignatoryDeclined",
  IpsAdminDeclined: "IpsAdminDeclined",
  IpsSignatoryDeclined: "IpsSignatoryDeclined",
};

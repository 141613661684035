import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { supplierPreApproveSheet, ipsPreApproveSheet } from "./actions";
import {
  SheetActionIps,
  SheetActionPrincipal,
} from "features/../../../shared/constants/enums";
import { useDispatch } from "react-redux";

const PreApproveSheet = ({ sheet, sheetData, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();

  const { sheetId, sheetName } = sheet;
  const availableActions = process.env.TRADE_AS_IPS
    ? sheet
      ? sheet.availableActionsIps
      : sheetData.availableActionsIps
    : sheet
    ? sheet.availableActionsPrincipal
    : sheetData.availableActionsPrincipal;

  const isSupplier =
    availableActions[
      process.env.TRADE_AS_IPS
        ? SheetActionIps.CanBePreApprovedBySupplier
        : SheetActionPrincipal.CanBePreApprovedByPrincipal
    ];
  const approver = isSupplier
    ? t(process.env.TRADE_AS_IPS ? "Supplier" : "Principal")
    : availableActions[SheetActionIps.CanBePreApprovedByIPS]
    ? t("IPSAdmin")
    : null;

  if (approver == null) return null;

  return (
    <ModalBuilder
      submitAction={isSupplier ? supplierPreApproveSheet : ipsPreApproveSheet}
      submitParams={{ sheetId }}
      onSubmitted={() => {
        dispatch(
          notifySuccess(
            t("SheetActions.PreApproveSheet.Submitted", { approver }),
          ),
        );
        onSubmitted();
      }}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.PreApproveSheet.TriggerText", { approver })}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("SheetActions.PreApproveSheet.ModalHeader", { approver })}
          content={t("SheetActions.PreApproveSheet.ModalContent", {
            sheetName,
          })}
        />
      )}
    />
  );
};

export default PreApproveSheet;

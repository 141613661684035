import React from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { Form, Format } from "@redriver/cinnamon";
import { getSheetState } from "../selectors";
import { useTranslation, Trans } from "react-i18next";
import { BlankNumberFormatter } from "features/../../../shared/components/display";
import { updateData, calculateFormOnChange, calculateForm } from "./actions";
import { withCurrentUser } from "features/../../../shared/components/auth";

const ExcessAllocation = ({
  sheetData,
  rawCalculation,
  sheetDetailsSaving,
  preventUpdates,
  updateData,
  calculateFormOnChange,
  calculateForm,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("sheetSummary");
  const { isInternalUser } = currentUser;

  return (
    <Form
      value={sheetData}
      onChange={(field, data, applyChanges) => {
        dispatch(updateData(field, data, applyChanges));
        dispatch(calculateFormOnChange(data));
      }}
      onKeyDown={(e) => {
        //enter key
        if (e.keyCode === 13) {
          calculateForm(true);
        }
      }}
    >
      <Form.Object field="excessAllocation" propagateUpdates="always">
        <div className="sheet-summary">
          <div className="excess-allocation">
            <div className="summary-agreement incentive-info">
              <div className="incentive-row currency-label">
                <label>{t("Actual_Excess")}</label>
                <Form.Numeric
                  field="actualExcess"
                  decimalPlaces={2}
                  subLabel={process.env.MAJOR_CURRENCY}
                  subLabelPosition="left"
                  formatValue
                  disabled={
                    sheetDetailsSaving || (preventUpdates && !isInternalUser)
                  }
                />
              </div>
              <div className="incentive-row">
                <label>{t("Excess_Allocated_to_Schedule")}</label>
                <Form.Numeric
                  field="excessAllocatedToSchedule"
                  decimalPlaces={2}
                  subLabel={process.env.MAJOR_CURRENCY}
                  subLabelPosition="left"
                  formatValue
                  disabled={sheetDetailsSaving || preventUpdates}
                />
              </div>
              <div className="incentive-row">
                <span className="red-label">
                  <Trans
                    t={t}
                    components={{
                      highlight: <span className="red-text bold" />,
                    }}
                  >
                    Excess_Not_Allocated_to_Schedule
                  </Trans>
                </span>
                {rawCalculation?.excessAllocation
                  ?.excessNotAllocatedToSchedule == null ? (
                  "-"
                ) : (
                  <Format.Number
                    value={
                      rawCalculation?.excessAllocation
                        ?.excessNotAllocatedToSchedule
                    }
                    format={process.env.CURRENCY_CODE}
                  />
                )}
              </div>
            </div>
            <div className="summary-agreement incentive-info">
              <div className="incentive-row">
                <label>{t("Finance_CPI")}</label>
                <BlankNumberFormatter
                  decimalPlaces={3}
                  value={
                    rawCalculation?.breakdown?.capitalCalcCosts
                      ?.capitalCostPerClick
                  }
                />
              </div>
              <div className="incentive-row">
                <label>{t("Excess_And_Volume_Credit")}</label>
                <BlankNumberFormatter
                  decimalPlaces={0}
                  value={rawCalculation?.excessAllocation?.excessVolumeCredit}
                />
              </div>
              <div className="incentive-row">
                <label>{t("Include_Excess_And_Volume_Credit")}</label>
                <div className="includeExcessAndVolumeCredit">
                  <Form.Checkbox
                    field="includeExcessAndVolumeCredit"
                    disabled={sheetDetailsSaving || preventUpdates}
                  />
                  <span
                    style={{
                      visibility: rawCalculation?.excessAllocation
                        ?.includeExcessAndVolumeCredit
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {t("Included")}
                  </span>
                </div>
              </div>
              <div className="incentive-row">
                <label>{t("TMV_Before_Volume")}</label>
                <BlankNumberFormatter
                  decimalPlaces={0}
                  value={rawCalculation?.excessAllocation?.tmvBeforeVolume}
                />
              </div>
              <div className="incentive-row">
                <label>{t("TMV_After_Volume")}</label>
                <BlankNumberFormatter
                  decimalPlaces={0}
                  value={rawCalculation?.excessAllocation?.tmvAfterVolume}
                />
              </div>
              <div className="incentive-row">
                <label>{t("Total_Secondary_Rentals")}</label>
                <Form.Numeric
                  field="totalSecondaryRentals"
                  decimalPlaces={2}
                  subLabel={process.env.MAJOR_CURRENCY}
                  subLabelPosition="left"
                  formatValue
                  disabled={
                    sheetDetailsSaving || (preventUpdates && !isInternalUser)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Form.Object>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { sheetData, rawCalculation, sheetDetailsSaving } =
    getSheetState(state);
  return {
    sheetData,
    rawCalculation,
    sheetDetailsSaving,
    preventUpdates: sheetData.preventUpdates,
  };
};

const actions = (dispatch) => ({
  updateData,
  calculateFormOnChange,
  calculateForm: () => dispatch(calculateForm(true)),
});

export default compose(
  connect(mapStateToProps, actions),
  withCurrentUser,
)(ExcessAllocation);

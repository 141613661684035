import { apiPut } from "@redriver/cinnamon";

const ActionTypes = {
  preApprovalDeclined: "SHEETS/PRE_APPROVAL_DECLINED",
};

export const supplierPreApprovalDeclined = (request, { sheetId }) =>
  apiPut(
    ActionTypes.preApprovalDeclined,
    `sheets/${sheetId}/submit/supplier-pre-approval-declined`,
    request,
  );

export const ipsPreApprovalDeclined = (request, { sheetId }) =>
  apiPut(
    ActionTypes.preApprovalDeclined,
    `sheets/${sheetId}/submit/ips-pre-approval-declined`,
    request,
  );
